import React, { useState, useEffect } from "react";
import { Images } from "../../assets/assets";
import Slider from "../../components/Slider/Slider";
import PrimaryButton from "../../components/Button/PrimaryButton";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import Swiper from "../../components/Swiper/Swiper";
import RecommendationItem from "../../components/RecommendationItem/RecommendationItem";
import VideoPreview from "../../components/VideoPreview/VideoPreview";
import { BlogsService } from "../../APIs";
import { Helmet } from "react-helmet";

const recommendations = [
  {
    image: Images.HomeBottomImage1,
    title: "Together we can make a difference",
    subTitle: "SodaStream’s environmental commitment.",
    link: "/why-sodastream",
  },
  {
    image: Images.HomeBottomImage2,
    title: "The benefits of drinking sparkling water",
    subTitle: "Six benefits of drinking sparkling water.",
    link: "/blog/2",
  },
  {
    image: Images.HomeBottomImage3,
    title: "Drink your mood with SodaStream mixology",
    subTitle:
      "Discover our best sparkling water cocktail recipes, mocktail recipes and more!",
    link: "/blog/3",
  },
  {
    image: Images.HomeBottomImage4,
    title: "Make it yours",
    subTitle: "SodaStream sparkling water offers endless possibilities.",
    link: "/blog/4",
  },
];

const HeaderTextStyle = {};

const HomePage = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  //listen for window resize event, and update dimensions
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const [blogs, setBlogs] = useState<any[]>([]);

  const fetchBlogsData = async () => {
    try {
      const { data } = await BlogsService.getAllBlogs();
      if (data) {
        setBlogs(data);
      } else {
        console.error("Error fetching blogs:");
      }
    } catch (error) {
      console.error("Error fetching :");
    }
  };

  function toUrlSlug(text: string) {
    const decoded = decodeURIComponent(text);
    const hyphenated = decoded.replace(/\s+/g, "-");
    return hyphenated;
  }

  // Call the method
  useEffect(() => {
    fetchBlogsData();
  }, []);

  const capitalizeFirstLetter = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {"SodaStream Official SA Store: Sparkling Water Makers & Gas Cylinders"}
        </title>

        <meta
          name="description"
          content={
            "SodaStream is the first choice for Home Water Carbonation System in South Africa. Checkout our range of Sparkling water machines/makers, bottles and flavours."
          }
        />
        <link rel="canonical" href="https://www.sodastream.co.za/" />
        <meta
          name="keywords"
          content="home water carbonation system,
           home water carbonation system south africa, sparkling water machine for home"
        />

        <meta name="avg-search-volume" content="10 10 40" />

        <meta
          name="search-intent"
          content="Informational, Commercial, Commercial"
        />
      </Helmet>

      <div className={" relative z-10 mb-0 w-full"}>
        {screenSize.width < 1060 && <Navbar />}
        <Slider images={[Images.HomeBG4]} />
        <div className="absolute z-20 top-[170px] md:top-0 left-0 w-full">
          {screenSize.width > 1060 && <Navbar />}
        </div>
      </div>
      {/*  */}
      <section className="bg-[#75A7AD] md:h-[300px]">
        <div className="flex gap-14 md:px-15 p-5 text-start items-center">
          <div className="flex-col flex gap-2">
            <p className="text-[#F9C645] md:text-[60px] text-[18px] mt-0 mb-0">
              Running low on pshh-pshh?
            </p>
            <p className="text-white md:text-[35px] text-[15px]">
              Buy gas at your favourite retailer.
            </p>
            <Link to="/store-locator">
              <PrimaryButton label="STORE LOCATOR" />
            </Link>
          </div>
          <div>
            <div className="md:max-w-[350px] md:block hidden">
              <img
                src={Images.WatterBottles}
                width="100%"
                height="100%"
                alt="water bottles"
              />
            </div>
          </div>
        </div>
      </section>
      {/*  */}

      <section>
        <div className="flex gap-0 flex-wrap md:flex-nowrap">
          <div className="basis-[100%] md:basis-[50%] relative bg-[#75A7AD80] w-full">
            <div className="md:absolute t-0 left-0">
              <img src={Images.ArtImg} alt="art" className="hidden md:block" />
              <div className="md:pl-10 pl-10 py-10">
                <p className="text-[#E56C5C] md:text-[30px] text-[14px] text-start mb-2">
                  Sparkling Water Makers
                </p>
                <Link to="/water-makers">
                  <PrimaryButton label="Explore ART" />
                </Link>
              </div>
            </div>
            <div>
              <img
                className="absolute bottom-0 right-[0] w-[30%] md:w-[25%] md:block"
                src={Images.WatterBottleBlack2}
                alt="water bottle black"
              />
            </div>
            <div>
              <img
                className="absolute bottom-0 right-20 w-[22%] md:w-[30%] hidden md:block"
                src={Images.Glass}
                alt="water bottle black"
              />
            </div>
          </div>

          <div className="flex-grow bg-[#005D6E]">
            <div className="md:py-20 md:px-20 p-10 flex flex-col gap-5">
              <div className="flex flex-wrap gap-10 text-white w-full justify-between">
                <p className="text-[#F9C645] text-[24px]">Co2 Gas Cylinders</p>
              </div>
              <Link to="co2-gas-cylinders">
                <PrimaryButton label="Explore Gas Cylinders" />
              </Link>
            </div>
            {/*  */}
            <div className={`h-[316px] relative md:block hidden`}>
              <img
                src={Images.GasTextImg}
                alt="gas"
                className="hidden md:block"
              />
              <img
                className="absolute bottom-0 right-16 hidden md:block"
                src={Images.ColoredWatterBottle}
                alt="SodaStream Bottles"
                style={{ height: "90%" }}
              />
            </div>
          </div>
        </div>
      </section>

      {/*  */}

      <section>
        <div className="bg-[#EEEAE5] relative w-full overflow-hidden min-h-[550px]">
          {" "}
          {/* Add overflow-hidden */}
          <div className="absolute top-0 hidden md:block">
            <img
              src={Images.DeepBlueSodastreamRipple}
              style={{ width: "55%" }}
              alt="Deep Blue Soda Stream"
            />
          </div>
          <div
            className="absolute top-1/2 left-2 z-50 flex flex-col gap-5 md:flex-row w-full md:px-20 px-10"
            style={{ transform: "translateY(-50%)" }}
          >
            <div className="text-start flex flex-col gap-2  basis-[50%] mt-[20px]">
              <p className="text-[#75A7AD] font-bold text-[25px] text-center">
                One SodaStream.
              </p>
              <p className="text-[#E56C5C] font-bold text-[24px] text-center">
                Endless possibilities.
              </p>
              <div className="flex justify-center">
  <Link to="/flavours">
    <PrimaryButton label="Explore Your Dreams" />
  </Link>
</div>

            </div>
            {/* //TODO: make it bigger on mobile screen instead of hidding them */}
            <div className="md:basis-[100%]">
              <Swiper
                images={[
                  { src: Images.Prod7Up, alt: "" },
                  { src: Images.ProdExtremeEnergy, alt: "" },
                  { src: Images.ProdPepsiMax, alt: "" },
                  { src: Images.ProdGingerBeer, alt: "" },
                  { src: Images.ProdPepsi, alt: "" },
                  { src: Images.ProdMiranda, alt: "" },
                  { src: Images.ProdIceTeaPeach, alt: "" },
                  { src: Images.ProdTonic3, alt: "" },
                  { src: Images.ProdClassicsCreamSoda, alt: "" },
                  { src: Images.ProdLemonade, alt: "" },
                ]}
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="flex gap-0 flex-wrap md:flex-nowrap">
          <div className="md:basis-[50%] md:block hidden flex-grow">
            <img
              className="h-full"
              src={Images.PouringFromFuseBottle}
              alt="bg"
              width={"100%"}
              height={"100%"}
            />
          </div>

          {/*  */}
          <div className="bg-[#75A7AD] p-20 md:basis-[50%] w-full flex flex-col items-start gap-4">
            <h2 className="text-[#F9C645] text-[22px]">Bottles</h2>
            <p className="text-white">Everything you need to know.</p>
            <Link to="/accessories">
              <PrimaryButton label="EXPLORE NOW" />
            </Link>
          </div>
        </div>
      </section>

      <section>
        <div className="relative min-h-[500px] w-full bg-[#EEEAE5] p-20 md:px-40 px-10 flex justify-between items-center">
          <div className="md:basis-[50%] basis-[100%]">
            <div className="flex flex-col gap-4 items-start justify-center text-start md:w-2/3">
              <p className="text-[#A0BA86] text-[20px]">
                One SodaStream can save up to thousands of single use plastic
                bottles.
              </p>
              <p className="text-[#707070]">
                If I use a SodaStream Sparkling Water Maker, I will save in a
                month ~ 71 plastic bottles
              </p>
              <Link to={"/why-sodastream"}>
                <PrimaryButton label="MORE INFO" />
              </Link>
            </div>
          </div>
          <div className="basis-[40%] md:block hidden">
            <img
              src={Images.GroupedSodaStreamRipple}
              alt="SodaStream - My only water"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </section>

      <section>
        <div>
          <VideoPreview
            video={
              "https://drive.google.com/file/d/1Pl7k5cyZ8orY32KQcz8e-OeGVe6dQhn0/preview"
            }
            bg={Images.VideoBG}
          />
        </div>
      </section>

      <section>
        <div className="bg-[#EEEAE5] px-7 py-8 flex flex-col gap-4">
          <section>
            <div className="relative   md:z-50 z-9 flex flex-col justify-center w-full items-center mb-[20px]">
              <h2 className="text-[20px] text-[#005D6E] box-border text-[18px] 
               leading-normal mb-0 mt-0 mr-1.5 ml-1.5  font-poppins">CHANGING THE WAY</h2>
              <p className="text-[#E56C5C] font-bold text-[25px] text-[20px] text-[#005D6E] box-border text-[18px] 
               leading-normal mb-0 mt-0 mr-1.5 ml-1.5  font-poppins">MZANSI DRINKS</p>
            </div> 
            

            <section>
        <div>
        <div className="mt-4">
            
            
            <div>
            <div className="flex flex-wrap md:flex-nowrap w-full">
  {blogs.map((card) => (
    <div key={card.id} className="w-full md:w-1/3 flex items-center justify-center mb-6 md:mb-0 md:mr-6 last:mr-0">
      <Link to={`/blog/${toUrlSlug(card.bloglink)}`}>
        <div className="max-w-sm dark:bg-gray-800 flex flex-col h-full">
        <div className="h-[300px] mx-auto w-full sm:w-[300px]">
  <img className="rounded-[15px] w-full h-full object-cover" src={card.image} alt={card.title} />
  </div>


          <div className="mt-[15px] flex-grow flex flex-col justify-between">
            <div className="flex-grow">
              <p className="box-border font-semibold text-[#005D6E] text-[18px] h-[70px] leading-normal mb-0 mt-0 mr-1.5 ml-1.5 text-left font-poppins">
                {card.title}
              </p>
            </div>

            <button className='text-[#005D6E] w-full max-w-[120px] border-[2px] border-[#75A7AD] h-[30px]
             text-[16px] font-semibold
               rounded-full bg-white flex items-center 
               justify-center hover:bg-[#005D6E] hover:text-white focus:outline-none mt-4'>
              Read More
            </button>
          </div>
        </div>
      </Link>
    </div>
  ))}
</div>

 </div>
          </div>
        </div>
      </section>
         
          </section>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default HomePage;