import React, { useContext, useEffect, useReducer, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";

import { AiOutlineClose } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { UserService } from "../../../APIs/index";
import { AuthContext } from "../../AuthContext/AuthContext";
import { ToastSuccess } from "../../Loading/Tost";
import {
  DeleteButton,
  IsLoadingButton,
  ModalDeleteCancel,
  ModalDeleteConfirm,
  ModalDeleteHeader,
  ModalDeleteTitle,
} from "../../Loading/ButtonLoading";
import FormComponent from "../MemberContainer/MemberForm";
import { FormData, User } from "../../../@types/productInfo";
import { FormNav } from "../../FormNav/FormNav";
import ModalForm from "../ModalForm/ModalForm";
import ModalButton from "../../Button/ModalButton";


const ACTIONS = {
  CREATE_MEMBER: "CREATE_MEMBER"
}


const reducer =(state:any, action:any)=>{
  switch (action.type){
    case ACTIONS.CREATE_MEMBER : return {showModal:false, isSuccessUser:true, selectedUser:null }
  }

  return state
}

const defaultValue = {
  showModal:false,
  howModalEdit:false,
  isSuccessUser:false,
  isSuccessMessageVisible:false,
  sModalVisible:false,
  selectedUser:null


}


const ManageMembers: React.FC = () => {

  const [state, dispach] = useReducer(reducer, defaultValue)


  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState("all");
  const [addRole, setAddRole] = useState("admin");
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);
  const [isSuccessUser, setSuccessUser] = useState(false);
  const [isEditSuccess, setEditSuccess] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(Array<any>);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleButtonClickk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    role: "admin",
    password: "",
  });

  const handleRoleChange = (event: any) => {
    setSelectedRole(event.target.value);
  };

  const handleAddChange = (event: any) => {
    setAddRole(event.target.value);
  };

  const handleSearchTermChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filterUsersFromSearch = (users: any[]) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const tempUsers: any[] = users.filter((user: any) => {
      const userProperties = Object.values(user).map((value) =>
        String(value).toLowerCase()
      );
      return (
        (selectedRole === "all" || user.role === selectedRole) &&
        userProperties.some((property) =>
          property.includes(lowerCaseSearchTerm)
        )
      );
    });

    console.log("tempUsers: ", tempUsers, lowerCaseSearchTerm);
    setFilteredUsers(tempUsers);
  };

  const filterUsersFromRole = (users: any[]) => {
    const tempUsers: any[] = users.filter(
      (user: any) => selectedRole === "all" || user.role === selectedRole
    );

    console.log("tempUsers: ", tempUsers, selectedRole);
    setFilteredUsers(tempUsers);
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterUsersFromSearch(users);
    } else {
      setFilteredUsers(users);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedRole !== "all") {
      filterUsersFromRole(users);
    }
  }, [selectedRole]);

  const handleFormSubmit = async (data: any) => {
    console.log("format", data);
    const userData: User = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      role: data.role,
      createAt: new Date().toISOString(),
    };

    try {
      setIsLoading(true);
      const { data, error } = await UserService.createUser(userData);

      if (data) {
        console.log("User created successfully:", data);
        
        fetchUserData();
        setSuccessUser(true);
        setShowModal(false);
        setSelectedUser(null);
        setTimeout(() => {
          setSuccessUser(false);
        }, 3000);

        setSelectedRole("");
      } else {
        console.error("Error creating user:", error);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    } finally {
      //set loading to false
      setIsLoading(false);
    }
  };

  //to show the user specific data
  const handleEditUser = (user: any) => {
    setSelectedUser(user);
    setFirstName(user.firstName || "");
    setLastName(user.lastName || "");
    setEmail(user.email || "");
    setAddRole(user.role || "");
    setPassword(user.password || "");
    setShowModalEdit(true);
  };

  const handleSaveEdit = async (formData: FormData) => {
    if (selectedUser) {
      const updatedUserData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        role: formData.role,
      };

      try {
        setIsLoadingUpdate(true);

        await UserService.editUser(selectedUser.id, updatedUserData);
        fetchUserData();
        setShowModalEdit(false);
        setEditSuccess(true);
        setSelectedUser(null);

        setTimeout(() => {
          setEditSuccess(false);
        }, 3000);
      } catch (error) {
        console.error("Error updating user:", error);
      } finally {
        setIsLoadingUpdate(false);
      }
    }
  };

  //update the user
  const fetchUserData = async () => {
    try {
      const { data, error } = await UserService.getAllUsers();
      if (data) {
        setUsers(data);
        setFilteredUsers(data);
      } else {
        console.error("Error fetching users:", error);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  //call the method
  useEffect(() => {
    fetchUserData();
  }, []);

  //delete the user
  const handleDeleteUser = async () => {
    try {
      setIsLoadingDelete(true);
      const userIdToDelete = selectedUser?.id;

      if (!userIdToDelete) {
        console.error("Selected user ID is missing.");
        return;
      }
      const { data, error } = await UserService.deleteUser(userIdToDelete);
      if (data) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.id !== userIdToDelete)
        );
        console.log("User deleted successfully:", data);
        setSuccessMessageVisible(true);

        fetchUserData();
        setShowModalEdit(false);
        setSelectedUser(null);
        setIsModalVisible(false);

        setTimeout(() => {
          setSuccessMessageVisible(false);
        }, 3000);
      } else {
        console.error("Error deleting user:", error);
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      setIsModalVisible(false);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <div className="p-4 rounded-md w-3/4 mx-auto">
      {isSuccessMessageVisible && (
        <ToastSuccess label="Member Delete Successfully!" />
      )}
      {isEditSuccess && <ToastSuccess label="Member Updated Successfully!" />}
      {isSuccessUser && <ToastSuccess label="Member added Successfully!" />}

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-black">Manage Members</h2>

        <div></div>
      </div>

      {/* Add new user */}
      <div className="">
        <div className="relative mt-4">
          {showModal && (
            <div>
              <ModalForm
                isOpen={showModal}
                modalId={""}
                closeModal={closeModal}
              >
                <FormNav label="Add Member" onClick={closeModal} />

                <div>
                  <FormComponent
                    isUpdate={false}
                    onDelete={undefined}
                    formData={formData}
                    onSubmit={handleFormSubmit}
                    onClick={closeModal}
                  />
                </div>
              </ModalForm>
            </div>
          )}
        </div>
      </div>

      <div className="relative mt-4">
        {showModalEdit && selectedUser && (
          <ModalForm
            isOpen={showModalEdit}
            modalId={""}
            closeModal={closeModalEdit}
          >
            <FormNav label="Edit Member" onClick={closeModalEdit} />
            <div>
              <FormComponent
                formData={{
                  firstName,
                  lastName,
                  email,
                  role: addRole,
                  password,
                }}
                onSubmit={handleSaveEdit}
                isUpdate
                isUpdateDelete
                onClick={handleButtonClickk}
              />
              

              {isModalVisible && (
                <div className="fixed
                 backdrop-blur-sm top-0
                  left-0 w-full h-[120%] bg-gray-800 opacity-30 z-50"></div>
              )}

              {isModalVisible && (
                <div
                  id="popup-modal"
                  tabIndex={-1}
                  className="overflow-y-auto mt-[7%] grid overflow-x-hidden fixed top-0 
                  right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                  <div className="relative p-4 w-full text-black max-w-md max-h-full">
                    <div
                      className="relative bg-white rounded-lg text-black shadow dark:bg-gray-700"
                      style={{ zIndex: 999 }}
                    >
                      <ModalDeleteHeader
                        onClick={handleModalClose}
                        label="close Modal"
                      />

                      <div className="p-4 md:p-5 text-center">
                        <ModalDeleteTitle label=" Are you sure you want to delete this member ?" />
                        {!isLoadingDelete && (
                          <div>
                            <ModalDeleteConfirm
                              label="Confirm"
                              onClick={handleDeleteUser}
                            />

                            <ModalDeleteCancel
                              label="Cancel"
                              onClick={handleModalClose}
                            />
                          </div>
                        )}

                        {isLoadingDelete && (
                          <IsLoadingButton
                            type="submit"
                            label="Deleting Member..."
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalForm>
        )}
      </div>
      <div className="bg-white p-4 rounded-md w-5/4 mx-auto">
        <ModalButton
          title="All Members"
          label="Add Member"
          onClick={openModal}
        />
        <div className="flex items-center mb-4">
          <input
            className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-1/4 mr-4 bg-white"
            placeholder="Search Member"
            onChange={handleSearchTermChange}
            value={searchTerm}
          />
          <div className="flex-grow"></div>
          <div className="form-group gender text-gray-500 p-2 rounded-md w-auto border-2">
            <select
              id="category"
              name="category"
              className="mt-1 block w-full rounded-md focus:outline-none text-sm bg-white"
              onChange={handleRoleChange}
              value={selectedRole}
            >
              <option value="all">All</option>
              <option value="admin">Admin</option>
              <option value="super_admin">Super Admin</option>
            </select>
          </div>
        </div>

        {/* display user's information  */}
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className=" min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="w-full text-xs">
                  <thead>
                    <tr>
                      <th className="border-b p-2 bg-white text-left">
                        Last Name
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Surname
                      </th>
                      <th className="border-b p-2 bg-white text-left">
                        Email Address
                      </th>
                      <th className="border-b p-2 bg-white text-left">Role</th>
                      <th className="border-b p-2 bg-white text-left">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user, index) => (
                      <tr key={index}>
                        <td className="border-b p-2 bg-white text-left">
                          {user.firstName}
                        </td>
                        <td className="border-b p-2 bg-white text-left text-black font-bold">
                          {user.lastName}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          {user.email}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          {user.role}
                        </td>
                        <td className="border-b p-2 bg-white text-left">
                          <span
                            onClick={() => handleEditUser(user)}
                            className="cursor-pointer rounded flex items-center"
                          >
                            <FaEdit />
                            Edit
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageMembers;
