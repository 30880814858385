import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import StoreLocator from "./pages/StoreLocator/StoreLocator";
import Flavours from "./pages/Flavours/Flavours";
import ProductPage from "./pages/ProductPage/ProductPage";
import MixologiesPage from "./pages/MixologiesPage/MixologiesPage";
import { defaultRec } from "./@types/productInfo";
import MixologyPage from "./pages/MixologyPage/MixologyPage";
import SparklingWaterMaker from "./pages/SparklingWaterMaker/SparklingWaterMaker";
import WaterMakerProductPage from "./pages/WaterMakerProductPage/WaterMakerProductPage";
import TerraWaterMakerPage from "./pages/WaterMakerProductPage/TerraWaterMakerPage";
import AccessoriesPage from "./pages/Accessories/AccessoriesPage";
import AccessoriesProductPage from "./pages/AccessoriesProductPage/AccessoriesProductPage";
import BlogPage from "./pages/BlogsPage/BlogsPage";
import PromotionsPage from "./pages/PromotionsPage/PromotionsPage";
import TrackOrder from "./pages/TrackOrder/TrackOrder";
import SupportPage from "./pages/SupportPage/SupportPage";
import WhySodastream from "./pages/WhySodastream/WhySodastream";
import RegisterMachine from "./pages/RegisterMachine/RegisterMachine";
import TermsPolicy from "./pages/Terms/TermsPolicy";
import TermsCompetition from "./pages/Terms/TermsCompetition";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Signin from "./components/Signin/Signin";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Sidebar from "./components/Dashboard/Sidebar/Sidebar";
import { AuthProvider } from "./components/AuthContext/AuthContext";
import Dashboard from "./components/Dashboard/Dashboard";
import ProtectedRoute from "./components/ProtectedRouter/ProtectedRoute";
import { defaultProduct } from "./@types/productInfo";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import PreviewProduct from "./components/Dashboard/ProductForm/PreviewProduct";
import AccessoryPreviewPage from "./components/Dashboard/accesscontent/AccessoryPreviewPage";
import RecipesPreviewPage from "./components/Dashboard/recipesContent/RecipesPreviewPage";
import WaterMachinePreview from "./components/Dashboard/watermachineform/WaterMachinePreview";
import GasCylinders from "./pages/GasCyLinders/GasCylinders";
import GasRefillsPage from "./pages/GasRefillsPage/GasRefillsPage";
import SpareCylinder from "./pages/SpareCylinder/SpareCYlinder";

const AppRoute: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <AuthProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/store-locator" element={<StoreLocator />} />
          <Route path="/track-order" element={<TrackOrder />} />
          <Route path="/why-sodastream" element={<WhySodastream />} />
          <Route path="/register-machine" element={<RegisterMachine />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/flavours" element={<Flavours {...defaultProduct} />} />;
          <Route
            path="/mixology/:recipelink"
            element={<MixologyPage {...defaultRec} />}
          />
          <Route path="/recipe-preview" element={<RecipesPreviewPage />} />
          <Route />
          <Route
            path="/water-machine-preview"
            element={<WaterMachinePreview />}
          />
          <Route
            path="/mixology"
            element={<MixologiesPage {...defaultRec} />}
          />
          ;
          <Route path="/product/:productlinkname" element={<ProductPage />} />
          <Route
            path="/product/96"
            element={
              <Navigate
                to="/product/crafted-ginger-beer-beverage-mix"
                replace
              />
            }
          />
          <Route
            path="/product/95"
            element={
              <Navigate to="/product/7up-diet-flavour-beverage-mix" replace />
            }
          />
          <Route
            path="/product/94"
            element={
              <Navigate to="/product/mirinda-flavour-beverage-mix" replace />
            }
          />
          <Route
            path="/product/93"
            element={
              <Navigate to="/product/classics-tonic-beverage-mix" replace />
            }
          />
          <Route
            path="/product/92"
            element={
              <Navigate to="/product/pepsi-zero-sugar-beverage-mix" replace />
            }
          />
          <Route
            path="/product/91"
            element={<Navigate to="/product/pepsi-beverage-mix" replace />}
          />
          <Route
            path="/product/86"
            element={
              <Navigate to="/product/sensations-iced-tea-peach" replace />
            }
          />
          <Route
            path="/product/176"
            element={
              <Navigate
                to="/product/zero-cranberry-raspberry-beverage-mix"
                replace
              />
            }
          />
          <Route
            path="/product/176"
            element={
              <Navigate
                to="/product/zero-cranberry-raspberry-beverage-mix"
                replace
              />
            }
          />
          <Route
            path="/product/175"
            element={<Navigate to="/product/lemonade-beverage-mix" replace />}
          />
          <Route
            path="/product/178"
            element={
              <Navigate to="/product/7up-flavour-beverage-mix" replace />
            }
          />
          <Route
            path="/product/170"
            element={
              <Navigate to="/product/classics-tonic-beverage-mix" replace />
            }
          />
          {/* mixology */}
          <Route
            path="/mixology/73"
            element={<Navigate to="/mixology/the-new-old-fashion" replace />}
          />
          <Route
            path="/mixology/72"
            element={<Navigate to="/mixology/new-years-sparkler" replace />}
          />
          <Route
            path="/mixology/71"
            element={<Navigate to="/mixology/autumn-elixir" replace />}
          />
          <Route
            path="/mixology/69"
            element={<Navigate to="/mixology/you-re-a-jam" replace />}
          />

          <Route
            path="/accessories/50"
            element={
              <Navigate to="/accessories/my-only-bottle-icy-blue" replace />
            }
          />
          <Route
            path="/accessories/60"
            element={
              <Navigate
                to="/accessories/sodastream-carbonating-fuse-bottle-pack"
                replace
              />
            }
          />
          <Route
            path="/accessories/87"
            element={
              <Navigate
                to="/accessories/my-only-bottle-icy-blue"
                replace
              />
            }
          />
          
          <Route
            path="/water-makers/2"
            element={
              <Navigate
                to="/water-makers/terra-sparkling-water-maker"
                replace
              />
            }
          />
          <Route
            path="/water-makers/36"
            element={
              <Navigate
                to="/water-makers/terra-sparkling-water-maker"
                replace
              />
            }
          />
          <Route
            path="/water-makers/31"
            element={
              <Navigate to="/water-makers/art-sparkling-water-maker" replace />
            }
          />
          <Route
            path="/c02-gas-cylinders"
            element={<Navigate to="/co2-gas-cylinders" replace />}
          />
          <Route
            path="/blog/99"
            element={
              <Navigate to="/blog/benefits-of-using-sparkling-water" replace />
            }
          />
          <Route
            path="/blog/98"
            element={
              <Navigate
                to="/blog/benefits-of-drinking-8-glasses-of-water-a-day"
                replace
              />
            }
          />
          
          <Route
            path="/blog/97"
            element={<Navigate to="/blog/stay-hydrated-at-work" replace />}
          />
          <Route
            path="/blog/1"
            element={<Navigate to="/blog/mini-kitchen-revamp" replace />}
          />
          <Route
            path="/terms"
            element={<Navigate to="/terms-and-conditions" replace />}
          />
          <Route path="/water-makers" element={<SparklingWaterMaker />} />
          <Route
            path="/water-makers/:machinelink"
            element={<WaterMakerProductPage />}
          />
          <Route path="/water-makers/2" element={<TerraWaterMakerPage />} />
          {/* Déclaration du nouveau lien */}
          <Route path="/accessories" element={<AccessoriesPage />} />
          <Route path="/terms-and-conditions" element={<TermsPolicy />} />
          <Route path="/competition-terms" element={<TermsCompetition />} />
          <Route
            path="/accessories/:accessorylink"
            element={<AccessoriesProductPage />}
          />
          <Route path="/co2-gas-cylinders" element={<GasCylinders />} />
          <Route path="/spare-cylinders" element={<SpareCylinder />} />
         
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/promotions" element={<PromotionsPage />} />
          <Route path="/blog/:bloglink" element={<BlogDetails />} />
          <Route path="/sidebar" element={<Sidebar />} />
          <Route path="/recipe-preview" element={<RecipesPreviewPage />} />
          <Route
            path="/dashboard/*"
            element={<ProtectedRoute path="/" element={<Dashboard />} />}
          />
          <Route path="/signin" element={<Signin />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/preview" element={<PreviewProduct />} />
          <Route path="/accessory-preview" element={<AccessoryPreviewPage />} />
          <Route element={<div>lost</div>} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
    
  );
};

export default AppRoute;
