import React from 'react'
import Header from '../../components/Header/Header';
import { Images } from '../../assets/assets';
import Footer from '../../components/Footer/Footer';
import SecondaryButton from '../../components/Button/SecondaryButton';

const TrackOrder = () => {
  return (
    <div>
        <Header backgroundImg={Images.Page19BG} title='Track Orders' textBackground='TRACKING' />
        <div className='relative min-h-[0rem] w-full bg-transparent'>
            <div className='relative md:top-[-20rem] gap-10 md:z-50 z-9 flex flex-col items-start justify-center w-full'>
                <div className='mt-5 flex flex-col items-start justify-start md:w-full md:px-60'>
                    <h2 className='md:text-[#005D6E] text-black font-bold text-[24px]'>Track your SodaStream order!</h2>
                    <p className='md:text-[#005D6E] text-black text-[14px]'>Fill out the information below:</p>
                </div>
                <div className='flex flex-col md:flex-row gap-4 w-full px-5 md:px-60'>
                    <input className='rounded-full w-full md:pl-10 basis-[37%]' placeholder='Order Number' />
                    <input className='rounded-full w-full md:pl-10 basis-[37%]' placeholder='Email Address' />
                    <div>
                        <SecondaryButton label='Track'/>
                    </div>
                </div>
                <p className='md:text-[#005D6E] text-black text-start md:px-60'>OR FILL IN YOUR TRACKING NUMBER BELOW</p>
                <div className='flex gap-4 w-full md:px-60'>
                    <input className='rounded-full w-full pl-10 basis-[37%]' placeholder='Tracking Number' />
                    <div>
                        <SecondaryButton label='Track'/>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default TrackOrder;