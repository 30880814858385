import React from 'react'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'

const Pagination = () => {
  return (
    <div className='flex gap-5 mt-5 items-center justify-center'>
    <span className='mr-5'><BsChevronLeft /></span>
    <span className='rounded-full bg-[#005D6E] text-white px-3 py-1'>1</span>
    {/* <span>2</span>
    <span>3</span>
    <span>4</span>
    <span>5</span> */}
    <span className='ml-5'><BsChevronRight /></span>
</div>
  )
}

export default Pagination