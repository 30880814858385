import React, { ReactNode } from "react";
import Dropdown from "../../components/Dropdown/Dropdown";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";
// import { BiCartAlt } from 'react-icons/bi';
import { Images } from "../../assets/assets";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { HiOutlineUser, HiMenu, HiOutlineLocationMarker } from "react-icons/hi";

import { BsPerson } from "react-icons/bs";
interface DropdownLabelProps {
  label: string;
  icon?: ReactNode;
}
type User = {
  username: string;
  email: string;
  token: string;
};

const DropdownLabel: React.FC<DropdownLabelProps> = ({ label, icon }) => (
  <div className="flex gap-1">
    <span>{label}</span>
    <span>{icon}</span>
  </div>
);

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "features",
    title: "Features",
  },
  {
    id: "product",
    title: "Product",
  },
  {
    id: "clients",
    title: "Clients",
  },
];

const Navbar = () => {
  const [active, setActive] = React.useState("Home");
  const [toggle, setToggle] = React.useState(false);
  const [isProductOpen, setIsProductOpen] = React.useState(false);
  const [isInspireOpen, setIsInspireOpen] = React.useState(false);
  const [isGasOpen, setIsGasOpen] = React.useState(false);

  const toggleDrawer = () => {
    console.log(toggle);
    setToggle((prevState) => !prevState);
  };

  return (
    <>
      <nav className="bg-white m-10 px-10 pr-0 rounded-full hidden md:block">
        <div className="flex gap-1">
          <div className="">
            <div className="w-[225px]">
              <Link to="/">
                <img
                  src={Images.LogoGreen}
                  alt="logo"
                  className="w-full h-full"
                />
              </Link>
            </div>
          </div>
          <div className="flex">
            <div className="flex items-center justify-center">
              <Dropdown
                isShopMenu
                toggle={() => {
                  setIsInspireOpen(false);
                  setIsGasOpen(false);
                  setIsProductOpen(!isProductOpen);
                }}
                isOpen={isProductOpen}
                setIsOpen={setIsProductOpen}
                label={
                  <DropdownLabel label="PRODUCTS" icon={<BsChevronDown />} />
                }
                items={[
                  { path: "/flavours", label: "Flavours" },
                  { path: "/water-makers", label: "Sparkling Water Makers" },
                ]}
                path={""}
              />
            </div>
            <div className="flex items-center justify-center">
              <Dropdown
                toggle={() => {
                  setIsInspireOpen(!isInspireOpen);
                  setIsGasOpen(false);
                  setIsProductOpen(false);
                }}
                isOpen={isInspireOpen}
                setIsOpen={setIsInspireOpen}
                label={
                  <DropdownLabel label="INSPIRE ME" icon={<BsChevronDown />} />
                }
                items={[
                  { path: "/mixology", label: "Mixology" },
                  { path: "/why-sodastream", label: "Environment" },
                  { path: "/blog", label: "Blog" },
                ]}
                path={""}
              />
            </div>
            <div className="flex items-center justify-center">
              <Dropdown
                toggle={() => {
                  setIsInspireOpen(false);
                  setIsGasOpen(!isGasOpen);
                  setIsProductOpen(false);
                }}
                isOpen={isGasOpen}
                setIsOpen={setIsGasOpen}
                label={<DropdownLabel label="GET GAS" icon={<BsChevronDown />} />}
                items={[
                  { path: "/co2-gas-cylinders", label: "C02 Gas Cylinders" },
                  { path: "/spare-cylinders", label: "Spare Cylinders" },
                 
                ]}
                path={""}
              />
            </div>
          </div>
          <div className="flex-grow flex">
            <div className="flex-grow flex items-center justify-center">
              <Link className="text-[16px]" to={"/support"}>
                Support
              </Link>
            </div>
            <div className="flex-grow flex items-center justify-center">
              <Link className="text-[16px]" to={"/register-machine"}>
                Register your machine
              </Link>
            </div>
            <div className="flex-grow flex items-center justify-center">
              <Link className="text-[16px]" to={"/store-locator"}>
                Store Locator
              </Link>
            </div>
          </div>

          <div className="flex-grow flex items-center justify-center bg-[#75A7AD] px-6 rounded-r-full">
            <div className="flex px-5 items-center justify-center">
              <Link to={"/store-locator"}>
                <HiOutlineLocationMarker color="white" size={30} />
              </Link>
              <div>{/* <BiCartAlt color='white' /> */}</div>
            </div>
          </div>
        </div>
      </nav>

      <div className="md:hidden z-50 block w-full items-center">
        <div className="flex-col">
          <nav className="bg-white w-[100%] pr-0 md:hidden">
            <div className="flex  w-[100%] justify-between">
              <div className="">
                <div className="z-20 h-[100%]">
                  {/* <input type="checkbox" id="drawer-toggle" className="relative sr-only peer" onChange={() => setToggle(!toggle)} checked={toggle} /> */}
                  <button
                    onClick={toggleDrawer}
                    className="text-white p-5 flex justify-center bg-[#75A7AD]  h-[100%] "
                  >
                    <HiMenu fontSize={30} />
                  </button>
                </div>
              </div>
              <div className="">
                <div className="w-[225px]">
                  <Link to="/">
                    <img
                      src={Images.LogoGreen}
                      alt="logo"
                      className="w-full h-full"
                    />
                  </Link>
                </div>
              </div>
              <div className="flex">
                {/* <div className="z-20 h-[100%]">
      
        <Link to="/signin" className="text-white p-5 flex bg-[#75A7AD] h-[100%]">
            <BsPerson fontSize={30} />
        </Link>
    </div> */}
                <div className="z-20 h-[100%]">
                  {/* <input type="checkbox" id="drawer-toggle" className="relative sr-only peer" onChange={() => setToggle(!toggle)} checked={toggle} /> */}
                  <Link
                    to="/store-locator"
                    className="text-white p-5 flex bg-[#75A7AD] h-[100%]"
                  >
                    <HiOutlineLocationMarker fontSize={30} />
                  </Link>
                </div>
              </div>
            </div>
          </nav>
          <div className="w-full bg-white flex">
            <Link to="/water-makers" className="bold p-5 w-1/3">
              Machines
            </Link>
            <Link to="/co2-gas-cylinders" className="bold p-5 w-1/3">
              Get Gas
            </Link>
            <Link to="/flavours" className="bold p-5 w-1/3">
              Flavours
            </Link>
          </div>
        </div>
        <Drawer open={toggle} onClose={toggleDrawer} direction="left">
          <div
            className="float-left ml-1 font text-[#005D6E] cursor-pointer text-lg"
            onClick={toggleDrawer}
          >
            x
          </div>
          <div className="px-6 py-4">
            <h2 className="text-lg font-semibold">
              {" "}
              <Link className="text-[16px]" to={"/"}>
                Home
              </Link>
            </h2>
            <p className="text-gray-500">
              {" "}
              <Link className="text-[16px]" to={"/flavours"}>
                Flavours
              </Link>
            </p>
            <p className="text-gray-500">
              {" "}
              <Link className="text-[16px] text-left" to={"/water-makers"}>
                Sparkling Water Makers
              </Link>
            </p>
            <p className="text-gray-500">
              <Link className="text-[16px]" to={"/mixology"}>
                Mixology{" "}
              </Link>
            </p>
            <p className="text-gray-500">
              <Link className="text-[16px]" to={"/accessories"}>
                Bottles{" "}
              </Link>
            </p>
            <p className="text-gray-500">
              <Link className="text-[16px]" to={"/blog"}>
                Blogs{" "}
              </Link>
            </p>
            <p className="text-gray-500">
              <Link className="text-[16px]" to={"/co2-gas-cylinders"}>
                C02 Gas Cylinders
              </Link>
            </p>
            <p className="text-gray-500">
              <Link className="text-[16px]" to={"/spare-cylinders"}>
                Spare Cylinders
              </Link>
            </p>
            <p className="text-gray-500">
              <Link className="text-[16px]" to={"/gas-refills"}>
                Gas Refills
              </Link>
            </p>
            <p className="text-gray-500">
              <Link className="text-[16px]" to={"/store-locator"}>
                Store Locator
              </Link>
            </p>
          </div>
        </Drawer>        
      </div>
    </>
  );
};

export default Navbar;