import axios, { AxiosResponse } from "axios";
import { StoreData } from "../@types/productInfo";


// const BASE_URL = "http://localhost:5005/stores";
const BASE_URL = "https://sodastreamapi.azurewebsites.net/stores";

class StoreService {

  async getAllStores(): Promise<{
    data: StoreData[] | null;
    error: string | null;
  }> {
    try {
      const response: AxiosResponse<StoreData[]> = await axios.get(BASE_URL);
      if (response.status >= 200 && response.status < 300) {
        const stores: StoreData[] = response.data;
        console.log("Stores:", stores);
        return { data: stores, error: null };
      } else {
        const error = "Error fetching stores";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching stores:", error);
      return { data: null, error };
    }
  }

  async getStoreById(
    storeId: number
  ): Promise<{ data: StoreData | null; error: string | null }> {
    try {
      const response: AxiosResponse<StoreData> = await axios.get(
        `${BASE_URL}/${storeId}`
      );
      if (response.status >= 200 && response.status < 300) {
        const store: StoreData = response.data;
        console.log("Store:", store);
        return { data: store, error: null };
      } else {
        const error = "Error fetching store by ID";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error fetching store by ID:", error);
      return { data: null, error };
    }
  }

  async  createStoreList(storeDataList: StoreData[]): Promise<{ data: StoreData[] | null; error: string | null }> {
    try {
      const createdStores: StoreData[] = [];
      
    
        const response: AxiosResponse<StoreData> = await axios.post(`${BASE_URL}/list`, storeDataList);
        
        if (response.status >= 200 && response.status < 300) {
          const createdStore: StoreData = response.data;
          console.log("Created Store:", createdStore);
          createdStores.push(createdStore);
        } else {
          const error = "Error creating store";
          console.error(error);
          return { data: null, error };
        }
      
      
      return { data: createdStores, error: null };
    } catch (error: any) {
      console.error("Error creating stores:", error);
      return { data: null, error };
    }
  }

  async createStore(
    storeData: StoreData
  ): Promise<{ data: StoreData | null; error: string | null }> {
    try {
      const response: AxiosResponse<StoreData> = await axios.post(
        BASE_URL,
        storeData
      );
      if (response.status >= 200 && response.status < 300) {
        const createdStore: StoreData = response.data;
        console.log("Created Store:", createdStore);
        return { data: createdStore, error: null };
      } else {
        const error = "Error creating store";
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error creating store:", error);
      return { data: null, error };
    }
  }

  async editStore(
    storeId: number,
    updatedStoreData: StoreData
  ): Promise<{ data: StoreData | null; error: string | null }> {
    try {
      const response: AxiosResponse<StoreData> = await axios.put(
        `${BASE_URL}/${storeId}`,
        updatedStoreData
      );
      if (response.status >= 200 && response.status < 300) {
        const editedStore: StoreData = response.data;
        console.log("Edited Store:", editedStore);
        return { data: editedStore, error: null };
      } else {
        const error = `Error editing store: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error editing store:", error.message);
      return { data: null, error: error.message };
    }
  }

  async deleteStore(
    storeId: number
  ): Promise<{ data: boolean | null; error: string | null }> {
    try {
      const response: AxiosResponse<void> = await axios.delete(
        `${BASE_URL}/${storeId}`
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Store deleted successfully");
        return { data: true, error: null };
      } else {
        const error = `Error deleting store: ${response.statusText}`;
        console.error(error);
        return { data: null, error };
      }
    } catch (error: any) {
      console.error("Error deleting store:", error.message);
      return { data: null, error: error.message };
    }
  }
}

const storeServiceInstance = new StoreService();

export default storeServiceInstance;
