import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { FaCloudUploadAlt, FaEdit, FaUpload } from "react-icons/fa";
import { AccessoriesClass } from "../../../APIs/index";

import {
  DeleteButton,
  IsLoadingButton,
  ModalDeleteCancel,
  ModalDeleteConfirm,
  ModalDeleteHeader,
  ModalDeleteTitle,
  SubmitButton,
} from "../../Loading/ButtonLoading";
import { ToastSuccess, ToastWarning } from "../../Loading/Tost";
import FormComponent from "../accesscontent/AccessContent";
import {
  Access,
  AccessData,
  AccessProps,
  AccessoryData,
} from "../../../@types/productInfo";
import ModalForm from "../ModalForm/ModalForm";
import { FormNav } from "../../FormNav/FormNav";
import ModalButton from "../../Button/ModalButton";
const Accessories: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [caption, setCaption] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState("all");
  const [selectedAccessory, setSelectedAccessory] = useState<Access | null>(
    null
  );

  const [accessories, setAccessories] = useState<Access[]>([]);
  const [isSuccessMessageVisible, setSuccessMessageVisible] = useState(false);

  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [accessorylink, setAccessoryLink] = useState("");
  const [meta_title, setMetaTitle] = useState("");
  const [meta_description, setMetaDescription] = useState("");
  const [filteredAccs, setFilteredAccs] = useState<Access[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isSuccessAcc, setSuccessAcc] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleButtonClickk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  const closeModalEdit = () => {
    setShowModalEdit(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSearchTermChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filterAccSearch = (accessories: any[]) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const temp: any[] = accessories.filter((user: any) => {
      const productProperties = Object.values(user).map((value) =>
        String(value).toLowerCase()
      );
      return (
        (selectedRole === "all" || user.role === selectedRole) &&
        productProperties.some((property) =>
          property.includes(lowerCaseSearchTerm)
        )
      );
    });

    console.log("temp: ", temp, lowerCaseSearchTerm);
    setFilteredAccs(temp);
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      filterAccSearch(accessories);
    } else {
      setFilteredAccs(accessories);
    }
  }, [searchTerm]);

  const handleButtonClick = () => {
    setModalOpen(!isModalOpen);
  };

  const handleEditModal = (accessory: any) => {
    setSelectedAccessory(accessory);
    setEditModal(true);
  };

  ///

  // Function to handle form submission

  const handleFormSubmit = async (data: any) => {
    const userData: AccessoryData = {
      name: data.name,
      description: data.description,
      meta_title: data.meta_title,
      meta_description: data.meta_description,
      caption: data.caption,
      image: data.image,
      accessorylink,
    };
    try {
      setIsLoading(true);
      const { data, error } = await AccessoriesClass.createAccessory(userData);

      if (data) {
        console.log("Accessory created successfully:", data);
        fetchAccData();
        setShowModal(false);
        setSelectedRole("");
        setSuccessAcc(true);
        setTimeout(() => {
          setSuccessAcc(false);
        }, 3000);
      } else {
        console.error("Error creating user:", error);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    } finally {
      //set loading to false
      setIsLoading(false);
    }
  };

  // Function to fill the edit form
  const handleEditAcc = (acc: any) => {
    setSelectedAccessory(acc);
    setName(acc.name || "");
    setDescription(acc.description || "");
    setMetaTitle(acc.meta_title || "");
    setMetaDescription(acc.meta_description || "");
    setCaption(acc.caption || "");
    setImage(acc.image || "");
    setAccessoryLink(acc.accessorylink || "");
    setShowModalEdit(true);
  };

  const handleSaveEdit = async (accessoryData: AccessoryData) => {
    setIsLoadingUpdate(true);

    try {
      if (selectedAccessory && selectedAccessory.id) {
        const updatedAccData = {
          name: accessoryData.name,
          description: accessoryData.description,
          meta_description: accessoryData.meta_description,
          meta_title: accessoryData.meta_title,
          caption: accessoryData.caption,
          image: accessoryData.image || selectedAccessory.image,
          accessorylink: accessoryData.accessorylink,
        };

        console.log("Updating accessory with data:", updatedAccData);

        const { data, error } = await AccessoriesClass.editAcessory(
          selectedAccessory.id,
          updatedAccData
        );

        console.log("API Response:", data, error);

        if (data) {
          fetchAccData();
          setShowModalEdit(false);
          setEditSuccess(true);
          setSelectedAccessory(null);
          setTimeout(() => {
            setEditSuccess(false);
          }, 3000);
        } else {
          console.error("Error updating accessory:", error);
        }
      } else {
        console.error("Selected accessory or accessory id is undefined");
      }
    } catch (error) {
      console.error("Error updating accessory:", error);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  // Function to fetch all accessories
  const fetchAccData = async () => {
    try {
      const { data, error } = await AccessoriesClass.getAllAccessories();

      if (data) {
        setAccessories(data);
        setFilteredAccs(data);
      } else {
        console.error("Error fetching acc:", error);
      }
    } catch (error) {
      console.error("Error fetching acc:", error);
    }
  };

  // Call the method on component mount
  useEffect(() => {
    fetchAccData();
  }, []);

  // Function to handle deleting Access
  const handleDeleteAcc = async (productId: number) => {
    if (productId) {
      try {
        setIsLoadingDelete(true);
        const { data, error } = await AccessoriesClass.deleteAccessory(
          productId
        );

        if (data) {
          setAccessories((prev) => prev.filter((acc) => acc.id !== productId));
          console.log("Accessory deleted successfully:", data);
          setSuccessMessageVisible(true);
          fetchAccData();
          setShowModalEdit(false);
          setIsModalVisible(false);
          setSelectedAccessory(null);
          setTimeout(() => {
            setSuccessMessageVisible(false);
          }, 3000);
        } else {
          console.error("Error deleting product:", error);
        }
      } catch (error) {
        console.error("Error deleting product:", error);
      } finally {
        setIsLoadingDelete(false);
      }
    }
  };

  return (
    <div className="p-4 rounded-md w-5/4 mx-auto">
      {isSuccessMessageVisible && (
        <ToastSuccess label="Accessory Delete Successfully!" />
      )}
      {isEditSuccess && (
        <ToastSuccess label="Accessory Updated Successfully!" />
      )}
      {isSuccessAcc && <ToastSuccess label="Accessory added Successfully!" />}

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-black">Accessories</h2>
      </div>
      <div className="relative mt-4">
        {showModal && (
          <div>
            <ModalForm isOpen={showModal} modalId={""} closeModal={closeModal}>
              <FormNav label="Add Accessory" onClick={closeModal} />

              <div>
                <FormComponent
                  formData={{
                    name,
                    description,
                    caption,
                    meta_title,
                    meta_description,
                    image: image,
                    accessorylink
                  }}
                  onSubmit={handleFormSubmit}
                  onClick={closeModal}
                />
              </div>
            </ModalForm>
          </div>
        )}
      </div>

      <div className="relative mt-4">
        {showModalEdit && selectedAccessory && (
          <ModalForm
            isOpen={showModalEdit}
            modalId={""}
            closeModal={closeModalEdit}
          >
            <FormNav label="Edit Accessory" onClick={closeModalEdit} />

            <div>
              <FormComponent
                formData={{
                  name,
                  description,
                  meta_title,
                  meta_description,
                  caption,
                  image: image,
                  accessorylink
                }}
                onSubmit={handleSaveEdit}
                isUpdate
                isUpdateDelete
                onClick={handleButtonClickk}
              />
              {isModalVisible && (
                <div
                  className="
                 backdrop-blur-sm h-[145%] top-0 fixed
                  left-0 w-full bg-gray-800 opacity-30 z-100"
                ></div>
              )}
              {isModalVisible && (
                <div
                  id="popup-modal"
                  tabIndex={-1}
                  className="overflow-y-auto grid overflow-x-hidden mt-[30%] fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
                >
                  <div className="relative p-4 w-full text-black max-w-md max-h-full">
                    <div
                      className="relative bg-white rounded-lg text-black shadow dark:bg-gray-700"
                      style={{ zIndex: 999 }}
                    >
                      <ModalDeleteHeader
                        onClick={handleModalClose}
                        label="close Modal"
                      />

                      <div className="p-4 md:p-5 text-center">
                        <ModalDeleteTitle label=" Are you sure you want to delete this accessory ?" />
                        {!isLoadingDelete && (
                          <div>
                            <ModalDeleteConfirm
                              label="Confirm"
                              onClick={() =>
                                selectedAccessory?.id &&
                                handleDeleteAcc(selectedAccessory.id)
                              }
                            />

                            <ModalDeleteCancel
                              label="Cancel"
                              onClick={handleModalClose}
                            />
                          </div>
                        )}

                        {isLoadingDelete && (
                          <IsLoadingButton
                            type="submit"
                            label="Deleting Accessory..."
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalForm>
        )}
      </div>
      <div className="bg-white p-4 rounded-md w-5/4 mx-auto">
        <ModalButton
          title="All Accessories"
          label="Add Accessory"
          onClick={openModal}
        />
        <div className="flex items-center mb-4">
          <input
            className="border rounded-[0.50rem] border-gray-400 py-1 px-2 w-1/4 mr-4 bg-white"
            placeholder="Search Accessory"
            onChange={handleSearchTermChange}
            value={searchTerm}
          />
          <div className="flex-grow"></div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className=" min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="w-full text-xs">
                  <thead>
                    <tr>
                      <th className="border-b p-2 bg-white text-left">Image</th>
                      <th className="border-b p-2 bg-white text-left">Name</th>
                      <th className="border-b p-2 bg-white text-left">
                        Description
                      </th>

                      <th className="border-b p-2 bg-white text-left">
                        Caption
                      </th>

                      <th className="border-b p-2 bg-white text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredAccs.map((acc, index) => (
                      <tr key={index}>
                        <td className="border-b p-2 bg-white text-left font-bold">
                          <img
                            src={acc.image}
                            alt=""
                            className="w-10 h-30 object-cover"
                          />
                        </td>

                        <td className="border-b p-2 bg-white text-left text-black font-bold">
                          {acc.name}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {acc.description}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          {acc.caption}
                        </td>

                        <td className="border-b p-2 bg-white text-left">
                          <span
                            onClick={() => handleEditAcc(acc)}
                            className="cursor-pointer rounded flex items-center"
                          >
                            <FaEdit />
                            Edit
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accessories;
